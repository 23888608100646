import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component("Datepicker",Datepicker);
    nuxtApp.vueApp.mixin({
        beforeCreate() {
            if (this.$options.name === 'vue-datepicker') {
                this.$options.propsData.locale = 'en-US';
                // this.$options.propsData.timezone = 'Pacific/Midway';
                // this.$options.propsData.modeType = 'MM/dd/yyyy';
            }
        }
    })
})