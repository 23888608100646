import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47change_45password_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/edit/change-password/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/banner-list/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/banner-list/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/banner-list/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47page_45options_45banners_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/page-options-banners/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/campaigns/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/campaigns/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47crm_45_91id1_93_45_91id2_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/crm-[id1]-[id2].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47order_45history_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/order-history/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47subscriptions_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/subscriptions/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/issue-plan/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/issue-plan/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/issue-plan/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47add_91ids_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/bundles/add[ids].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/bundles/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/taxes/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/taxes/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/taxes/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/farms-and-stables/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/farms-and-stables/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/farms-and-stables/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47categories_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/edit/categories.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47error_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/error.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47file_45manager_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/file-manager/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47forms_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/forms.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/albums/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/albums/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/photographers/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/photographers/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/photographers/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47incomplete_45orders_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/incomplete-orders/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47just_45sold_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/just-sold/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/stallions/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/stallions/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/stallions/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/issues/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/issues/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47login_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/login.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47add_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/add/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47view_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/view/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45history_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-history/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/categories/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/categories/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/categories/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47page_45content_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/page-content.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/categories/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/categories/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/categories/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/testimonials/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/testimonials/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/testimonials/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertisers/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertisers/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/calendar/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/calendar/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/calendar/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/companies/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/companies/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/companies/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47current_45issue_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/current-issue/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47slider_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/slider/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/widgets/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/widgets/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/news/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/news/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/news/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47all_45american_45standings_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/results/all-american-standings/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47sire_45standings_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/results/sire-standings/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47subscribe_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/subscribe/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47terms_45conditions_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/terms-conditions/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/videos/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/videos/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47profile_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/profile.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47all_45american_45awards_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/all-american-awards/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47owners_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/owners/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47photos_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/photos/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47results_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/results/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47revenue_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/revenue/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47rider_45points_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/rider-points/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47riders_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/riders/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47sire_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/sire/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47banner_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/banner/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47digital_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/digital/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47directory_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/directory/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47horse_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/horse/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47print_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/print/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47users_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/users/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47responsive_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/responsive.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/aaads/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/aaads/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/aaads/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/breeds/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/breeds/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/breeds/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/classes/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/classes/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/classes/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/divisions/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/divisions/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/divisions/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47final_45form_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/final-form/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47horse_45_91id1_93_45_91id2_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/horse-[id1]-[id2].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47add_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/add/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47gallery_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/gallery/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47judge_45cards_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/judge-cards/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47video_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/video/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47owner_45_91id1_93_45_91id2_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/owner-[id1]-[id2].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47rider_45_91id1_93_45_91id2_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/rider-[id1]-[id2].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47add_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/stables/add.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/stables/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/stables/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47style_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/style.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47edit_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/subscriptions/edit/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/subscriptions/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47tables_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/tables.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47ui_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/ui.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47unauthorized_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/unauthorized.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertise_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/advertise/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/advertiser-gallery/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47photos_47_91slug_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/advertiser-gallery/photos/[slug].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47all_45american_45standings_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/all-american-standings/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47checkout_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/checkout/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47contact_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/contact/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47_91title_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-farms-stables/[title].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-farms-stables/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47_91title_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-products-services/[title].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-products-services/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/employment/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47print_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/employment/print/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47title_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/employment/title/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/event/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47view_47_91slug_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/event/view/[slug].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47add_45horse_45for_45sale_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/for-sale-gallery/add-horse-for-sale/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/for-sale-gallery/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47_91slug_93_47_91_46_46_46type_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/horse-shows/[slug]/[...type].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/horse-shows/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_47_91slug_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/horse/[slug].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47issue_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/issue/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47login_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/login.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47_91_46_46_46newsFilters_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/news/[...newsFilters].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47entry_47_91title_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/news/entry/[title].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45history_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/our-history/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45team_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/our-team/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47owner_47_91slug_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/owner/[slug].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/photo-gallery/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47photos_47_91slug_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/photo-gallery/photos/[slug].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47privacy_45policy_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/privacy-policy/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/reset-password/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47token_47_91token_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/reset-password/token/[token].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47rider_47_91slug_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/rider/[slug].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47search_45results_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/search-results/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47shop_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/shop/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47sire_45standings_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/sire-standings/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47site_45map_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/site-map/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47add_45stallion_45for_45sale_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/stallions-gallery/add-stallion-for-sale/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/stallions-gallery/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stl_45view_47_91slug_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/stl-view/[slug].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47subscribe_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/subscribe/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47terms_45conditions_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/terms-conditions/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47account_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/account/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47address_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/address/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47digital_45photos_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/digital-photos/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47favorite_45photos_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/favorite-photos/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/orders/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47view_47_91id_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/orders/view/[id].vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47subscriptions_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/subscriptions/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47videos_47index_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/videos/index.vue?macro=true";
import { default as _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47view_47_91slug_93_46vueMeta } from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/view/[slug].vue?macro=true";
export default [
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47add_46vueMeta?.name ?? "admin-admin-users-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47add_46vueMeta?.path ?? "/admin/admin-users/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/add.vue",
    children: [],
    url: "/admin/admin-users/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47_91id_93_46vueMeta?.name ?? "admin-admin-users-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47_91id_93_46vueMeta?.path ?? "/admin/admin-users/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47change_45password_47_91id_93_46vueMeta?.name ?? "admin-admin-users-edit-change-password-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47change_45password_47_91id_93_46vueMeta?.path ?? "/admin/admin-users/edit/change-password/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/edit/change-password/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47change_45password_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47change_45password_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47edit_47change_45password_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/edit/change-password/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47index_46vueMeta?.name ?? "admin-admin-users",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47index_46vueMeta?.path ?? "/admin/admin-users",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/index.vue",
    children: [],
    url: "/admin/admin-users",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47admin_45users_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/admin-users/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47add_46vueMeta?.name ?? "admin-banners-banner-list-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47add_46vueMeta?.path ?? "/admin/banners/banner-list/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/banner-list/add.vue",
    children: [],
    url: "/admin/banners/banner-list/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/banner-list/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47edit_47_91id_93_46vueMeta?.name ?? "admin-banners-banner-list-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47edit_47_91id_93_46vueMeta?.path ?? "/admin/banners/banner-list/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/banner-list/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/banner-list/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47index_46vueMeta?.name ?? "admin-banners-banner-list",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47index_46vueMeta?.path ?? "/admin/banners/banner-list",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/banner-list/index.vue",
    children: [],
    url: "/admin/banners/banner-list",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47banner_45list_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/banner-list/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47page_45options_45banners_47index_46vueMeta?.name ?? "admin-banners-page-options-banners",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47page_45options_45banners_47index_46vueMeta?.path ?? "/admin/banners/page-options-banners",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/page-options-banners/index.vue",
    children: [],
    url: "/admin/banners/page-options-banners",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47page_45options_45banners_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47page_45options_45banners_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47banners_47page_45options_45banners_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/banners/page-options-banners/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47edit_47_91id_93_46vueMeta?.name ?? "admin-campaigns-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47edit_47_91id_93_46vueMeta?.path ?? "/admin/campaigns/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/campaigns/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/campaigns/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47index_46vueMeta?.name ?? "admin-campaigns",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47index_46vueMeta?.path ?? "/admin/campaigns",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/campaigns/index.vue",
    children: [],
    url: "/admin/campaigns",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47campaigns_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47add_46vueMeta?.name ?? "admin-crm-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47add_46vueMeta?.path ?? "/admin/crm/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/add.vue",
    children: [],
    url: "/admin/crm/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47crm_45_91id1_93_45_91id2_93_46vueMeta?.name ?? "admin-crm-crm-id1-id2",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47crm_45_91id1_93_45_91id2_93_46vueMeta?.path ?? "/admin/crm/crm-:id1-:id2",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/crm-[id1]-[id2].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47crm_45_91id1_93_45_91id2_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47crm_45_91id1_93_45_91id2_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47crm_45_91id1_93_45_91id2_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/crm-[id1]-[id2].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47edit_47_91id_93_46vueMeta?.name ?? "admin-crm-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47edit_47_91id_93_46vueMeta?.path ?? "/admin/crm/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47index_46vueMeta?.name ?? "admin-crm",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47index_46vueMeta?.path ?? "/admin/crm",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/index.vue",
    children: [],
    url: "/admin/crm",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47order_45history_47_91id_93_46vueMeta?.name ?? "admin-crm-order-history-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47order_45history_47_91id_93_46vueMeta?.path ?? "/admin/crm/order-history/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/order-history/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47order_45history_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47order_45history_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47order_45history_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/order-history/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47subscriptions_47_91id_93_46vueMeta?.name ?? "admin-crm-subscriptions-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47subscriptions_47_91id_93_46vueMeta?.path ?? "/admin/crm/subscriptions/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/subscriptions/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47subscriptions_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47subscriptions_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47crm_47subscriptions_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/crm/subscriptions/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47add_46vueMeta?.name ?? "admin-definitions-issue-plan-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47add_46vueMeta?.path ?? "/admin/definitions/issue-plan/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/issue-plan/add.vue",
    children: [],
    url: "/admin/definitions/issue-plan/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/issue-plan/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47edit_47_91id_93_46vueMeta?.name ?? "admin-definitions-issue-plan-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47edit_47_91id_93_46vueMeta?.path ?? "/admin/definitions/issue-plan/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/issue-plan/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/issue-plan/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47index_46vueMeta?.name ?? "admin-definitions-issue-plan",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47index_46vueMeta?.path ?? "/admin/definitions/issue-plan",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/issue-plan/index.vue",
    children: [],
    url: "/admin/definitions/issue-plan",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47issue_45plan_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/issue-plan/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47add_46vueMeta?.name ?? "admin-definitions-offers-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47add_46vueMeta?.path ?? "/admin/definitions/offers/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/add.vue",
    children: [],
    url: "/admin/definitions/offers/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47add_91ids_93_46vueMeta?.name ?? "admin-definitions-offers-bundles-addids",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47add_91ids_93_46vueMeta?.path ?? "/admin/definitions/offers/bundles/add:ids",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/bundles/add[ids].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47add_91ids_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47add_91ids_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47add_91ids_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/bundles/add[ids].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47edit_47_91id_93_46vueMeta?.name ?? "admin-definitions-offers-bundles-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47edit_47_91id_93_46vueMeta?.path ?? "/admin/definitions/offers/bundles/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/bundles/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47bundles_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/bundles/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47edit_47_91id_93_46vueMeta?.name ?? "admin-definitions-offers-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47edit_47_91id_93_46vueMeta?.path ?? "/admin/definitions/offers/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47index_46vueMeta?.name ?? "admin-definitions-offers",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47index_46vueMeta?.path ?? "/admin/definitions/offers",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/index.vue",
    children: [],
    url: "/admin/definitions/offers",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47offers_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/offers/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47add_46vueMeta?.name ?? "admin-definitions-taxes-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47add_46vueMeta?.path ?? "/admin/definitions/taxes/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/taxes/add.vue",
    children: [],
    url: "/admin/definitions/taxes/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/taxes/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47edit_47_91id_93_46vueMeta?.name ?? "admin-definitions-taxes-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47edit_47_91id_93_46vueMeta?.path ?? "/admin/definitions/taxes/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/taxes/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/taxes/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47index_46vueMeta?.name ?? "admin-definitions-taxes",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47index_46vueMeta?.path ?? "/admin/definitions/taxes",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/taxes/index.vue",
    children: [],
    url: "/admin/definitions/taxes",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47definitions_47taxes_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/definitions/taxes/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47add_46vueMeta?.name ?? "admin-directory-farms-and-stables-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47add_46vueMeta?.path ?? "/admin/directory/farms-and-stables/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/farms-and-stables/add.vue",
    children: [],
    url: "/admin/directory/farms-and-stables/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/farms-and-stables/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47edit_47_91id_93_46vueMeta?.name ?? "admin-directory-farms-and-stables-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47edit_47_91id_93_46vueMeta?.path ?? "/admin/directory/farms-and-stables/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/farms-and-stables/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/farms-and-stables/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47index_46vueMeta?.name ?? "admin-directory-farms-and-stables",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47index_46vueMeta?.path ?? "/admin/directory/farms-and-stables",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/farms-and-stables/index.vue",
    children: [],
    url: "/admin/directory/farms-and-stables",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47farms_45and_45stables_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/farms-and-stables/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47add_46vueMeta?.name ?? "admin-directory-products-and-services-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47add_46vueMeta?.path ?? "/admin/directory/products-and-services/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/add.vue",
    children: [],
    url: "/admin/directory/products-and-services/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47_91id_93_46vueMeta?.name ?? "admin-directory-products-and-services-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47_91id_93_46vueMeta?.path ?? "/admin/directory/products-and-services/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47categories_46vueMeta?.name ?? "admin-directory-products-and-services-edit-categories",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47categories_46vueMeta?.path ?? "/admin/directory/products-and-services/edit/categories",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/edit/categories.vue",
    children: [],
    url: "/admin/directory/products-and-services/edit/categories",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47categories_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47categories_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47edit_47categories_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/edit/categories.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47index_46vueMeta?.name ?? "admin-directory-products-and-services",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47index_46vueMeta?.path ?? "/admin/directory/products-and-services",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/index.vue",
    children: [],
    url: "/admin/directory/products-and-services",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47directory_47products_45and_45services_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/directory/products-and-services/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47error_46vueMeta?.name ?? "admin-error",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47error_46vueMeta?.path ?? "/admin/error",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/error.vue",
    children: [],
    url: "/admin/error",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47error_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47error_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47error_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/error.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47file_45manager_47index_46vueMeta?.name ?? "admin-file-manager",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47file_45manager_47index_46vueMeta?.path ?? "/admin/file-manager",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/file-manager/index.vue",
    children: [],
    url: "/admin/file-manager",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47file_45manager_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47file_45manager_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47file_45manager_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/file-manager/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47forms_46vueMeta?.name ?? "admin-forms",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47forms_46vueMeta?.path ?? "/admin/forms",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/forms.vue",
    children: [],
    url: "/admin/forms",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47forms_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47forms_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47forms_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/forms.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47edit_47_91id_93_46vueMeta?.name ?? "admin-gallery-albums-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47edit_47_91id_93_46vueMeta?.path ?? "/admin/gallery/albums/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/albums/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/albums/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47index_46vueMeta?.name ?? "admin-gallery-albums",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47index_46vueMeta?.path ?? "/admin/gallery/albums",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/albums/index.vue",
    children: [],
    url: "/admin/gallery/albums",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47albums_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/albums/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47add_46vueMeta?.name ?? "admin-gallery-photographers-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47add_46vueMeta?.path ?? "/admin/gallery/photographers/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/photographers/add.vue",
    children: [],
    url: "/admin/gallery/photographers/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/photographers/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47edit_47_91id_93_46vueMeta?.name ?? "admin-gallery-photographers-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47edit_47_91id_93_46vueMeta?.path ?? "/admin/gallery/photographers/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/photographers/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/photographers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47index_46vueMeta?.name ?? "admin-gallery-photographers",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47index_46vueMeta?.path ?? "/admin/gallery/photographers",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/photographers/index.vue",
    children: [],
    url: "/admin/gallery/photographers",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47gallery_47photographers_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/gallery/photographers/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47add_46vueMeta?.name ?? "admin-horse-listings-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47add_46vueMeta?.path ?? "/admin/horse-listings/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/add.vue",
    children: [],
    url: "/admin/horse-listings/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47edit_47_91id_93_46vueMeta?.name ?? "admin-horse-listings-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47edit_47_91id_93_46vueMeta?.path ?? "/admin/horse-listings/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47incomplete_45orders_47index_46vueMeta?.name ?? "admin-horse-listings-incomplete-orders",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47incomplete_45orders_47index_46vueMeta?.path ?? "/admin/horse-listings/incomplete-orders",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/incomplete-orders/index.vue",
    children: [],
    url: "/admin/horse-listings/incomplete-orders",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47incomplete_45orders_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47incomplete_45orders_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47incomplete_45orders_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/incomplete-orders/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47index_46vueMeta?.name ?? "admin-horse-listings",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47index_46vueMeta?.path ?? "/admin/horse-listings",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/index.vue",
    children: [],
    url: "/admin/horse-listings",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47just_45sold_47index_46vueMeta?.name ?? "admin-horse-listings-just-sold",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47just_45sold_47index_46vueMeta?.path ?? "/admin/horse-listings/just-sold",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/just-sold/index.vue",
    children: [],
    url: "/admin/horse-listings/just-sold",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47just_45sold_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47just_45sold_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47just_45sold_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/just-sold/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47add_46vueMeta?.name ?? "admin-horse-listings-stallions-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47add_46vueMeta?.path ?? "/admin/horse-listings/stallions/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/stallions/add.vue",
    children: [],
    url: "/admin/horse-listings/stallions/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/stallions/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47edit_47_91id_93_46vueMeta?.name ?? "admin-horse-listings-stallions-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47edit_47_91id_93_46vueMeta?.path ?? "/admin/horse-listings/stallions/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/stallions/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/stallions/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47index_46vueMeta?.name ?? "admin-horse-listings-stallions",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47index_46vueMeta?.path ?? "/admin/horse-listings/stallions",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/stallions/index.vue",
    children: [],
    url: "/admin/horse-listings/stallions",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47horse_45listings_47stallions_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/horse-listings/stallions/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47index_46vueMeta?.name ?? "admin",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47index_46vueMeta?.path ?? "/admin",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/index.vue",
    children: [],
    url: "/admin",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47edit_47_91id_93_46vueMeta?.name ?? "admin-issues-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47edit_47_91id_93_46vueMeta?.path ?? "/admin/issues/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/issues/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/issues/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47index_46vueMeta?.name ?? "admin-issues",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47index_46vueMeta?.path ?? "/admin/issues",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/issues/index.vue",
    children: [],
    url: "/admin/issues",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47issues_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/issues/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47login_46vueMeta?.name ?? "admin-login",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47login_46vueMeta?.path ?? "/admin/login",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/login.vue",
    children: [],
    url: "/admin/login",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47login_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47login_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47login_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47add_47_91id_93_46vueMeta?.name ?? "admin-orders-add-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47add_47_91id_93_46vueMeta?.path ?? "/admin/orders/add/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/add/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47add_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47add_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47add_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/add/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47edit_47_91id_93_46vueMeta?.name ?? "admin-orders-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47edit_47_91id_93_46vueMeta?.path ?? "/admin/orders/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47index_46vueMeta?.name ?? "admin-orders",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47index_46vueMeta?.path ?? "/admin/orders",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/index.vue",
    children: [],
    url: "/admin/orders",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47view_47_91id_93_46vueMeta?.name ?? "admin-orders-view-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47view_47_91id_93_46vueMeta?.path ?? "/admin/orders/view/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/view/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47view_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47view_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47orders_47view_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/orders/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45history_47index_46vueMeta?.name ?? "admin-pages-about-our-history",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45history_47index_46vueMeta?.path ?? "/admin/pages/about/our-history",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-history/index.vue",
    children: [],
    url: "/admin/pages/about/our-history",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45history_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45history_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45history_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-history/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47add_46vueMeta?.name ?? "admin-pages-about-our-team-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47add_46vueMeta?.path ?? "/admin/pages/about/our-team/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/add.vue",
    children: [],
    url: "/admin/pages/about/our-team/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47add_46vueMeta?.name ?? "admin-pages-about-our-team-categories-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47add_46vueMeta?.path ?? "/admin/pages/about/our-team/categories/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/categories/add.vue",
    children: [],
    url: "/admin/pages/about/our-team/categories/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/categories/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-about-our-team-categories-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/about/our-team/categories/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/categories/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/categories/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47index_46vueMeta?.name ?? "admin-pages-about-our-team-categories",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47index_46vueMeta?.path ?? "/admin/pages/about/our-team/categories",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/categories/index.vue",
    children: [],
    url: "/admin/pages/about/our-team/categories",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47categories_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/categories/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-about-our-team-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/about/our-team/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47index_46vueMeta?.name ?? "admin-pages-about-our-team",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47index_46vueMeta?.path ?? "/admin/pages/about/our-team",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/index.vue",
    children: [],
    url: "/admin/pages/about/our-team",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47page_45content_46vueMeta?.name ?? "admin-pages-about-our-team-page-content",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47page_45content_46vueMeta?.path ?? "/admin/pages/about/our-team/page-content",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/page-content.vue",
    children: [],
    url: "/admin/pages/about/our-team/page-content",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47page_45content_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47page_45content_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47about_47our_45team_47page_45content_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/about/our-team/page-content.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47add_46vueMeta?.name ?? "admin-pages-advertise-categories-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47add_46vueMeta?.path ?? "/admin/pages/advertise/categories/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/categories/add.vue",
    children: [],
    url: "/admin/pages/advertise/categories/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/categories/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-advertise-categories-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/advertise/categories/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/categories/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/categories/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47index_46vueMeta?.name ?? "admin-pages-advertise-categories",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47index_46vueMeta?.path ?? "/admin/pages/advertise/categories",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/categories/index.vue",
    children: [],
    url: "/admin/pages/advertise/categories",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47categories_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/categories/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47add_46vueMeta?.name ?? "admin-pages-advertise-testimonials-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47add_46vueMeta?.path ?? "/admin/pages/advertise/testimonials/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/testimonials/add.vue",
    children: [],
    url: "/admin/pages/advertise/testimonials/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/testimonials/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-advertise-testimonials-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/advertise/testimonials/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/testimonials/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/testimonials/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47index_46vueMeta?.name ?? "admin-pages-advertise-testimonials",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47index_46vueMeta?.path ?? "/admin/pages/advertise/testimonials",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/testimonials/index.vue",
    children: [],
    url: "/admin/pages/advertise/testimonials",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertise_47testimonials_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertise/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-advertisers-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/advertisers/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertisers/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertisers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47index_46vueMeta?.name ?? "admin-pages-advertisers",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47index_46vueMeta?.path ?? "/admin/pages/advertisers",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertisers/index.vue",
    children: [],
    url: "/admin/pages/advertisers",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47advertisers_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/advertisers/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47add_46vueMeta?.name ?? "admin-pages-calendar-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47add_46vueMeta?.path ?? "/admin/pages/calendar/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/calendar/add.vue",
    children: [],
    url: "/admin/pages/calendar/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/calendar/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-calendar-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/calendar/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/calendar/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/calendar/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47index_46vueMeta?.name ?? "admin-pages-calendar",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47index_46vueMeta?.path ?? "/admin/pages/calendar",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/calendar/index.vue",
    children: [],
    url: "/admin/pages/calendar",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47calendar_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47add_46vueMeta?.name ?? "admin-pages-employment-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47add_46vueMeta?.path ?? "/admin/pages/employment/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/add.vue",
    children: [],
    url: "/admin/pages/employment/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47add_46vueMeta?.name ?? "admin-pages-employment-companies-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47add_46vueMeta?.path ?? "/admin/pages/employment/companies/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/companies/add.vue",
    children: [],
    url: "/admin/pages/employment/companies/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/companies/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-employment-companies-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/employment/companies/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/companies/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/companies/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47index_46vueMeta?.name ?? "admin-pages-employment-companies",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47index_46vueMeta?.path ?? "/admin/pages/employment/companies",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/companies/index.vue",
    children: [],
    url: "/admin/pages/employment/companies",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47companies_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/companies/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-employment-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/employment/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47index_46vueMeta?.name ?? "admin-pages-employment",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47index_46vueMeta?.path ?? "/admin/pages/employment",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/index.vue",
    children: [],
    url: "/admin/pages/employment",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47employment_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/employment/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47current_45issue_47index_46vueMeta?.name ?? "admin-pages-homepage-current-issue",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47current_45issue_47index_46vueMeta?.path ?? "/admin/pages/homepage/current-issue",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/current-issue/index.vue",
    children: [],
    url: "/admin/pages/homepage/current-issue",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47current_45issue_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47current_45issue_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47current_45issue_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/current-issue/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47slider_47index_46vueMeta?.name ?? "admin-pages-homepage-slider",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47slider_47index_46vueMeta?.path ?? "/admin/pages/homepage/slider",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/slider/index.vue",
    children: [],
    url: "/admin/pages/homepage/slider",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47slider_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47slider_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47slider_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/slider/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-homepage-widgets-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/homepage/widgets/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/widgets/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/widgets/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47index_46vueMeta?.name ?? "admin-pages-homepage-widgets",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47index_46vueMeta?.path ?? "/admin/pages/homepage/widgets",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/widgets/index.vue",
    children: [],
    url: "/admin/pages/homepage/widgets",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47homepage_47widgets_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/homepage/widgets/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47add_46vueMeta?.name ?? "admin-pages-news-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47add_46vueMeta?.path ?? "/admin/pages/news/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/news/add.vue",
    children: [],
    url: "/admin/pages/news/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/news/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-news-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/news/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/news/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/news/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47index_46vueMeta?.name ?? "admin-pages-news",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47index_46vueMeta?.path ?? "/admin/pages/news",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/news/index.vue",
    children: [],
    url: "/admin/pages/news",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47news_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47all_45american_45standings_47index_46vueMeta?.name ?? "admin-pages-results-all-american-standings",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47all_45american_45standings_47index_46vueMeta?.path ?? "/admin/pages/results/all-american-standings",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/results/all-american-standings/index.vue",
    children: [],
    url: "/admin/pages/results/all-american-standings",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47all_45american_45standings_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47all_45american_45standings_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47all_45american_45standings_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/results/all-american-standings/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47sire_45standings_47index_46vueMeta?.name ?? "admin-pages-results-sire-standings",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47sire_45standings_47index_46vueMeta?.path ?? "/admin/pages/results/sire-standings",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/results/sire-standings/index.vue",
    children: [],
    url: "/admin/pages/results/sire-standings",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47sire_45standings_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47sire_45standings_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47results_47sire_45standings_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/results/sire-standings/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47subscribe_47index_46vueMeta?.name ?? "admin-pages-subscribe",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47subscribe_47index_46vueMeta?.path ?? "/admin/pages/subscribe",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/subscribe/index.vue",
    children: [],
    url: "/admin/pages/subscribe",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47subscribe_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47subscribe_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47subscribe_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47terms_45conditions_47index_46vueMeta?.name ?? "admin-pages-terms-conditions",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47terms_45conditions_47index_46vueMeta?.path ?? "/admin/pages/terms-conditions",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/terms-conditions/index.vue",
    children: [],
    url: "/admin/pages/terms-conditions",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47terms_45conditions_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47terms_45conditions_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47terms_45conditions_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47edit_47_91id_93_46vueMeta?.name ?? "admin-pages-videos-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47edit_47_91id_93_46vueMeta?.path ?? "/admin/pages/videos/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/videos/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/videos/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47index_46vueMeta?.name ?? "admin-pages-videos",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47index_46vueMeta?.path ?? "/admin/pages/videos",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/videos/index.vue",
    children: [],
    url: "/admin/pages/videos",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47pages_47videos_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/pages/videos/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47profile_46vueMeta?.name ?? "admin-profile",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47profile_46vueMeta?.path ?? "/admin/profile",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/profile.vue",
    children: [],
    url: "/admin/profile",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47profile_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47profile_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47profile_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/profile.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47all_45american_45awards_47index_46vueMeta?.name ?? "admin-reports-all-american-awards",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47all_45american_45awards_47index_46vueMeta?.path ?? "/admin/reports/all-american-awards",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/all-american-awards/index.vue",
    children: [],
    url: "/admin/reports/all-american-awards",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47all_45american_45awards_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47all_45american_45awards_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47all_45american_45awards_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/all-american-awards/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47owners_47index_46vueMeta?.name ?? "admin-reports-owners",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47owners_47index_46vueMeta?.path ?? "/admin/reports/owners",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/owners/index.vue",
    children: [],
    url: "/admin/reports/owners",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47owners_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47owners_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47owners_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/owners/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47photos_47index_46vueMeta?.name ?? "admin-reports-photos",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47photos_47index_46vueMeta?.path ?? "/admin/reports/photos",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/photos/index.vue",
    children: [],
    url: "/admin/reports/photos",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47photos_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47photos_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47photos_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/photos/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47results_47index_46vueMeta?.name ?? "admin-reports-results",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47results_47index_46vueMeta?.path ?? "/admin/reports/results",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/results/index.vue",
    children: [],
    url: "/admin/reports/results",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47results_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47results_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47results_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/results/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47revenue_47index_46vueMeta?.name ?? "admin-reports-revenue",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47revenue_47index_46vueMeta?.path ?? "/admin/reports/revenue",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/revenue/index.vue",
    children: [],
    url: "/admin/reports/revenue",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47revenue_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47revenue_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47revenue_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/revenue/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47rider_45points_47index_46vueMeta?.name ?? "admin-reports-rider-points",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47rider_45points_47index_46vueMeta?.path ?? "/admin/reports/rider-points",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/rider-points/index.vue",
    children: [],
    url: "/admin/reports/rider-points",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47rider_45points_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47rider_45points_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47rider_45points_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/rider-points/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47riders_47index_46vueMeta?.name ?? "admin-reports-riders",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47riders_47index_46vueMeta?.path ?? "/admin/reports/riders",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/riders/index.vue",
    children: [],
    url: "/admin/reports/riders",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47riders_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47riders_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47riders_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/riders/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47sire_47index_46vueMeta?.name ?? "admin-reports-sire",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47sire_47index_46vueMeta?.path ?? "/admin/reports/sire",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/sire/index.vue",
    children: [],
    url: "/admin/reports/sire",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47sire_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47sire_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47sire_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/sire/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47banner_47index_46vueMeta?.name ?? "admin-reports-subscriptions-banner",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47banner_47index_46vueMeta?.path ?? "/admin/reports/subscriptions/banner",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/banner/index.vue",
    children: [],
    url: "/admin/reports/subscriptions/banner",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47banner_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47banner_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47banner_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/banner/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47digital_47index_46vueMeta?.name ?? "admin-reports-subscriptions-digital",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47digital_47index_46vueMeta?.path ?? "/admin/reports/subscriptions/digital",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/digital/index.vue",
    children: [],
    url: "/admin/reports/subscriptions/digital",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47digital_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47digital_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47digital_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/digital/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47directory_47index_46vueMeta?.name ?? "admin-reports-subscriptions-directory",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47directory_47index_46vueMeta?.path ?? "/admin/reports/subscriptions/directory",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/directory/index.vue",
    children: [],
    url: "/admin/reports/subscriptions/directory",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47directory_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47directory_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47directory_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/directory/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47horse_47index_46vueMeta?.name ?? "admin-reports-subscriptions-horse",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47horse_47index_46vueMeta?.path ?? "/admin/reports/subscriptions/horse",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/horse/index.vue",
    children: [],
    url: "/admin/reports/subscriptions/horse",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47horse_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47horse_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47horse_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/horse/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47print_47index_46vueMeta?.name ?? "admin-reports-subscriptions-print",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47print_47index_46vueMeta?.path ?? "/admin/reports/subscriptions/print",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/print/index.vue",
    children: [],
    url: "/admin/reports/subscriptions/print",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47print_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47print_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47subscriptions_47print_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/subscriptions/print/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47users_47index_46vueMeta?.name ?? "admin-reports-users",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47users_47index_46vueMeta?.path ?? "/admin/reports/users",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/users/index.vue",
    children: [],
    url: "/admin/reports/users",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47users_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47users_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47reports_47users_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/reports/users/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47responsive_46vueMeta?.name ?? "admin-responsive",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47responsive_46vueMeta?.path ?? "/admin/responsive",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/responsive.vue",
    children: [],
    url: "/admin/responsive",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47responsive_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47responsive_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47responsive_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/responsive.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47add_46vueMeta?.name ?? "admin-results-aaads-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47add_46vueMeta?.path ?? "/admin/results/aaads/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/aaads/add.vue",
    children: [],
    url: "/admin/results/aaads/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/aaads/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47edit_47_91id_93_46vueMeta?.name ?? "admin-results-aaads-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47edit_47_91id_93_46vueMeta?.path ?? "/admin/results/aaads/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/aaads/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/aaads/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47index_46vueMeta?.name ?? "admin-results-aaads",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47index_46vueMeta?.path ?? "/admin/results/aaads",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/aaads/index.vue",
    children: [],
    url: "/admin/results/aaads",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47aaads_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/aaads/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47add_46vueMeta?.name ?? "admin-results-breeds-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47add_46vueMeta?.path ?? "/admin/results/breeds/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/breeds/add.vue",
    children: [],
    url: "/admin/results/breeds/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/breeds/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47edit_47_91id_93_46vueMeta?.name ?? "admin-results-breeds-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47edit_47_91id_93_46vueMeta?.path ?? "/admin/results/breeds/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/breeds/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/breeds/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47index_46vueMeta?.name ?? "admin-results-breeds",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47index_46vueMeta?.path ?? "/admin/results/breeds",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/breeds/index.vue",
    children: [],
    url: "/admin/results/breeds",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47breeds_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/breeds/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47add_46vueMeta?.name ?? "admin-results-classes-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47add_46vueMeta?.path ?? "/admin/results/classes/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/classes/add.vue",
    children: [],
    url: "/admin/results/classes/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/classes/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47edit_47_91id_93_46vueMeta?.name ?? "admin-results-classes-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47edit_47_91id_93_46vueMeta?.path ?? "/admin/results/classes/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/classes/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/classes/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47index_46vueMeta?.name ?? "admin-results-classes",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47index_46vueMeta?.path ?? "/admin/results/classes",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/classes/index.vue",
    children: [],
    url: "/admin/results/classes",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47classes_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/classes/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47add_46vueMeta?.name ?? "admin-results-divisions-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47add_46vueMeta?.path ?? "/admin/results/divisions/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/divisions/add.vue",
    children: [],
    url: "/admin/results/divisions/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/divisions/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47edit_47_91id_93_46vueMeta?.name ?? "admin-results-divisions-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47edit_47_91id_93_46vueMeta?.path ?? "/admin/results/divisions/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/divisions/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/divisions/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47index_46vueMeta?.name ?? "admin-results-divisions",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47index_46vueMeta?.path ?? "/admin/results/divisions",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/divisions/index.vue",
    children: [],
    url: "/admin/results/divisions",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47divisions_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/divisions/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47final_45form_47index_46vueMeta?.name ?? "admin-results-final-form",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47final_45form_47index_46vueMeta?.path ?? "/admin/results/final-form",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/final-form/index.vue",
    children: [],
    url: "/admin/results/final-form",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47final_45form_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47final_45form_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47final_45form_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/final-form/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47add_46vueMeta?.name ?? "admin-results-horses-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47add_46vueMeta?.path ?? "/admin/results/horses/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/add.vue",
    children: [],
    url: "/admin/results/horses/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47edit_47_91id_93_46vueMeta?.name ?? "admin-results-horses-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47edit_47_91id_93_46vueMeta?.path ?? "/admin/results/horses/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47horse_45_91id1_93_45_91id2_93_46vueMeta?.name ?? "admin-results-horses-horse-id1-id2",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47horse_45_91id1_93_45_91id2_93_46vueMeta?.path ?? "/admin/results/horses/horse-:id1-:id2",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/horse-[id1]-[id2].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47horse_45_91id1_93_45_91id2_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47horse_45_91id1_93_45_91id2_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47horse_45_91id1_93_45_91id2_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/horse-[id1]-[id2].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47index_46vueMeta?.name ?? "admin-results-horses",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47index_46vueMeta?.path ?? "/admin/results/horses",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/index.vue",
    children: [],
    url: "/admin/results/horses",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47horses_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/horses/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47add_47_91id_93_46vueMeta?.name ?? "admin-results-index-add-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47add_47_91id_93_46vueMeta?.path ?? "/admin/results/add/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/add/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47add_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47add_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47add_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/add/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47edit_47_91id_93_46vueMeta?.name ?? "admin-results-index-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47edit_47_91id_93_46vueMeta?.path ?? "/admin/results/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47gallery_47_91id_93_46vueMeta?.name ?? "admin-results-index-gallery-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47gallery_47_91id_93_46vueMeta?.path ?? "/admin/results/gallery/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/gallery/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47gallery_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47gallery_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47gallery_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/gallery/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47index_46vueMeta?.name ?? "admin-results-index",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47index_46vueMeta?.path ?? "/admin/results",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/index.vue",
    children: [],
    url: "/admin/results",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47judge_45cards_47_91id_93_46vueMeta?.name ?? "admin-results-index-judge-cards-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47judge_45cards_47_91id_93_46vueMeta?.path ?? "/admin/results/judge-cards/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/judge-cards/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47judge_45cards_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47judge_45cards_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47judge_45cards_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/judge-cards/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47video_47_91id_93_46vueMeta?.name ?? "admin-results-index-video-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47video_47_91id_93_46vueMeta?.path ?? "/admin/results/video/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/video/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47video_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47video_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47index_47video_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/index/video/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47add_46vueMeta?.name ?? "admin-results-owners-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47add_46vueMeta?.path ?? "/admin/results/owners/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/add.vue",
    children: [],
    url: "/admin/results/owners/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47edit_47_91id_93_46vueMeta?.name ?? "admin-results-owners-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47edit_47_91id_93_46vueMeta?.path ?? "/admin/results/owners/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47index_46vueMeta?.name ?? "admin-results-owners",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47index_46vueMeta?.path ?? "/admin/results/owners",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/index.vue",
    children: [],
    url: "/admin/results/owners",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47owner_45_91id1_93_45_91id2_93_46vueMeta?.name ?? "admin-results-owners-owner-id1-id2",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47owner_45_91id1_93_45_91id2_93_46vueMeta?.path ?? "/admin/results/owners/owner-:id1-:id2",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/owner-[id1]-[id2].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47owner_45_91id1_93_45_91id2_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47owner_45_91id1_93_45_91id2_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47owners_47owner_45_91id1_93_45_91id2_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/owners/owner-[id1]-[id2].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47add_46vueMeta?.name ?? "admin-results-riders-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47add_46vueMeta?.path ?? "/admin/results/riders/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/add.vue",
    children: [],
    url: "/admin/results/riders/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47edit_47_91id_93_46vueMeta?.name ?? "admin-results-riders-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47edit_47_91id_93_46vueMeta?.path ?? "/admin/results/riders/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47index_46vueMeta?.name ?? "admin-results-riders",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47index_46vueMeta?.path ?? "/admin/results/riders",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/index.vue",
    children: [],
    url: "/admin/results/riders",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47rider_45_91id1_93_45_91id2_93_46vueMeta?.name ?? "admin-results-riders-rider-id1-id2",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47rider_45_91id1_93_45_91id2_93_46vueMeta?.path ?? "/admin/results/riders/rider-:id1-:id2",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/rider-[id1]-[id2].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47rider_45_91id1_93_45_91id2_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47rider_45_91id1_93_45_91id2_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47riders_47rider_45_91id1_93_45_91id2_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/riders/rider-[id1]-[id2].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47add_46vueMeta?.name ?? "admin-results-stables-add",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47add_46vueMeta?.path ?? "/admin/results/stables/add",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/stables/add.vue",
    children: [],
    url: "/admin/results/stables/add",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47add_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47add_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47add_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/stables/add.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47edit_47_91id_93_46vueMeta?.name ?? "admin-results-stables-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47edit_47_91id_93_46vueMeta?.path ?? "/admin/results/stables/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/stables/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/stables/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47index_46vueMeta?.name ?? "admin-results-stables",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47index_46vueMeta?.path ?? "/admin/results/stables",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/stables/index.vue",
    children: [],
    url: "/admin/results/stables",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47results_47stables_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/results/stables/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47style_46vueMeta?.name ?? "admin-style",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47style_46vueMeta?.path ?? "/admin/style",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/style.vue",
    children: [],
    url: "/admin/style",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47style_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47style_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47style_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/style.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47edit_47_91id_93_46vueMeta?.name ?? "admin-subscriptions-edit-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47edit_47_91id_93_46vueMeta?.path ?? "/admin/subscriptions/edit/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/subscriptions/edit/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47edit_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47edit_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47edit_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/subscriptions/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47index_46vueMeta?.name ?? "admin-subscriptions",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47index_46vueMeta?.path ?? "/admin/subscriptions",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/subscriptions/index.vue",
    children: [],
    url: "/admin/subscriptions",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47subscriptions_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47tables_46vueMeta?.name ?? "admin-tables",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47tables_46vueMeta?.path ?? "/admin/tables",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/tables.vue",
    children: [],
    url: "/admin/tables",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47tables_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47tables_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47tables_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/tables.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47ui_46vueMeta?.name ?? "admin-ui",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47ui_46vueMeta?.path ?? "/admin/ui",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/ui.vue",
    children: [],
    url: "/admin/ui",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47ui_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47ui_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47ui_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/ui.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47unauthorized_46vueMeta?.name ?? "admin-unauthorized",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47unauthorized_46vueMeta?.path ?? "/admin/unauthorized",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/unauthorized.vue",
    children: [],
    url: "/admin/unauthorized",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47unauthorized_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47unauthorized_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47admin_47unauthorized_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/admin/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertise_47index_46vueMeta?.name ?? "advertise",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertise_47index_46vueMeta?.path ?? "/advertise",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/advertise/index.vue",
    children: [],
    url: "/advertise",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertise_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertise_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertise_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/advertise/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47index_46vueMeta?.name ?? "advertiser-gallery",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47index_46vueMeta?.path ?? "/advertiser-gallery",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/advertiser-gallery/index.vue",
    children: [],
    url: "/advertiser-gallery",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/advertiser-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47photos_47_91slug_93_46vueMeta?.name ?? "advertiser-gallery-photos-slug",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47photos_47_91slug_93_46vueMeta?.path ?? "/advertiser-gallery/photos/:slug",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/advertiser-gallery/photos/[slug].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47photos_47_91slug_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47photos_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47advertiser_45gallery_47photos_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/advertiser-gallery/photos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47all_45american_45standings_47index_46vueMeta?.name ?? "all-american-standings",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47all_45american_45standings_47index_46vueMeta?.path ?? "/all-american-standings",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/all-american-standings/index.vue",
    children: [],
    url: "/all-american-standings",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47all_45american_45standings_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47all_45american_45standings_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47all_45american_45standings_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/all-american-standings/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47checkout_47index_46vueMeta?.name ?? "checkout",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47checkout_47index_46vueMeta?.path ?? "/checkout",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/checkout/index.vue",
    children: [],
    url: "/checkout",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47checkout_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47checkout_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47checkout_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47contact_47index_46vueMeta?.name ?? "contact",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47contact_47index_46vueMeta?.path ?? "/contact",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/contact/index.vue",
    children: [],
    url: "/contact",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47contact_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47contact_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47contact_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47_91title_93_46vueMeta?.name ?? "directory-farms-stables-title",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47_91title_93_46vueMeta?.path ?? "/directory-farms-stables/:title",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-farms-stables/[title].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47_91title_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47_91title_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47_91title_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-farms-stables/[title].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47index_46vueMeta?.name ?? "directory-farms-stables",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47index_46vueMeta?.path ?? "/directory-farms-stables",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-farms-stables/index.vue",
    children: [],
    url: "/directory-farms-stables",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45farms_45stables_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-farms-stables/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47_91title_93_46vueMeta?.name ?? "directory-products-services-title",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47_91title_93_46vueMeta?.path ?? "/directory-products-services/:title",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-products-services/[title].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47_91title_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47_91title_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47_91title_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-products-services/[title].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47index_46vueMeta?.name ?? "directory-products-services",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47index_46vueMeta?.path ?? "/directory-products-services",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-products-services/index.vue",
    children: [],
    url: "/directory-products-services",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47directory_45products_45services_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/directory-products-services/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47index_46vueMeta?.name ?? "employment",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47index_46vueMeta?.path ?? "/employment",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/employment/index.vue",
    children: [],
    url: "/employment",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/employment/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47print_47_91id_93_46vueMeta?.name ?? "employment-print-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47print_47_91id_93_46vueMeta?.path ?? "/employment/print/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/employment/print/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47print_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47print_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47print_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/employment/print/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47title_47_91id_93_46vueMeta?.name ?? "employment-title-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47title_47_91id_93_46vueMeta?.path ?? "/employment/title/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/employment/title/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47title_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47title_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47employment_47title_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/employment/title/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47index_46vueMeta?.name ?? "event",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47index_46vueMeta?.path ?? "/event",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/event/index.vue",
    children: [],
    url: "/event",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/event/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47view_47_91slug_93_46vueMeta?.name ?? "event-view-slug",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47view_47_91slug_93_46vueMeta?.path ?? "/event/view/:slug",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/event/view/[slug].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47view_47_91slug_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47view_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47event_47view_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/event/view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47add_45horse_45for_45sale_47index_46vueMeta?.name ?? "for-sale-gallery-add-horse-for-sale",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47add_45horse_45for_45sale_47index_46vueMeta?.path ?? "/for-sale-gallery/add-horse-for-sale",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/for-sale-gallery/add-horse-for-sale/index.vue",
    children: [],
    url: "/for-sale-gallery/add-horse-for-sale",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47add_45horse_45for_45sale_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47add_45horse_45for_45sale_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47add_45horse_45for_45sale_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/for-sale-gallery/add-horse-for-sale/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47index_46vueMeta?.name ?? "for-sale-gallery",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47index_46vueMeta?.path ?? "/for-sale-gallery",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/for-sale-gallery/index.vue",
    children: [],
    url: "/for-sale-gallery",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47for_45sale_45gallery_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/for-sale-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47_91slug_93_47_91_46_46_46type_93_46vueMeta?.name ?? "horse-shows-slug-type",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47_91slug_93_47_91_46_46_46type_93_46vueMeta?.path ?? "/horse-shows/:slug/:type(.*)*",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/horse-shows/[slug]/[...type].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47_91slug_93_47_91_46_46_46type_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47_91slug_93_47_91_46_46_46type_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47_91slug_93_47_91_46_46_46type_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/horse-shows/[slug]/[...type].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47index_46vueMeta?.name ?? "horse-shows",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47index_46vueMeta?.path ?? "/horse-shows",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/horse-shows/index.vue",
    children: [],
    url: "/horse-shows",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_45shows_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/horse-shows/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_47_91slug_93_46vueMeta?.name ?? "horse-slug",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_47_91slug_93_46vueMeta?.path ?? "/horse/:slug",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/horse/[slug].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_47_91slug_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47horse_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/horse/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47index_46vueMeta?.name ?? "index",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47index_46vueMeta?.path ?? "/",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/index.vue",
    children: [],
    url: "/",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47issue_47index_46vueMeta?.name ?? "issue",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47issue_47index_46vueMeta?.path ?? "/issue",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/issue/index.vue",
    children: [],
    url: "/issue",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47issue_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47issue_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47issue_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/issue/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47login_46vueMeta?.name ?? "login",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47login_46vueMeta?.path ?? "/login",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/login.vue",
    children: [],
    url: "/login",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47login_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47login_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47login_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47_91_46_46_46newsFilters_93_46vueMeta?.name ?? "news-newsFilters",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47_91_46_46_46newsFilters_93_46vueMeta?.path ?? "/news/:newsFilters(.*)*",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/news/[...newsFilters].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47_91_46_46_46newsFilters_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47_91_46_46_46newsFilters_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47_91_46_46_46newsFilters_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/news/[...newsFilters].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47entry_47_91title_93_46vueMeta?.name ?? "news-entry-title",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47entry_47_91title_93_46vueMeta?.path ?? "/news/entry/:title",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/news/entry/[title].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47entry_47_91title_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47entry_47_91title_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47news_47entry_47_91title_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/news/entry/[title].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45history_47index_46vueMeta?.name ?? "our-history",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45history_47index_46vueMeta?.path ?? "/our-history",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/our-history/index.vue",
    children: [],
    url: "/our-history",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45history_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45history_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45history_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/our-history/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45team_47index_46vueMeta?.name ?? "our-team",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45team_47index_46vueMeta?.path ?? "/our-team",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/our-team/index.vue",
    children: [],
    url: "/our-team",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45team_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45team_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47our_45team_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/our-team/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47owner_47_91slug_93_46vueMeta?.name ?? "owner-slug",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47owner_47_91slug_93_46vueMeta?.path ?? "/owner/:slug",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/owner/[slug].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47owner_47_91slug_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47owner_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47owner_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/owner/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47index_46vueMeta?.name ?? "photo-gallery",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47index_46vueMeta?.path ?? "/photo-gallery",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/photo-gallery/index.vue",
    children: [],
    url: "/photo-gallery",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/photo-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47photos_47_91slug_93_46vueMeta?.name ?? "photo-gallery-photos-slug",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47photos_47_91slug_93_46vueMeta?.path ?? "/photo-gallery/photos/:slug",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/photo-gallery/photos/[slug].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47photos_47_91slug_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47photos_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47photo_45gallery_47photos_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/photo-gallery/photos/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47privacy_45policy_47index_46vueMeta?.name ?? "privacy-policy",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47privacy_45policy_47index_46vueMeta?.path ?? "/privacy-policy",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/privacy-policy/index.vue",
    children: [],
    url: "/privacy-policy",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47privacy_45policy_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47privacy_45policy_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47privacy_45policy_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47index_46vueMeta?.name ?? "reset-password",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47index_46vueMeta?.path ?? "/reset-password",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/reset-password/index.vue",
    children: [],
    url: "/reset-password",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47token_47_91token_93_46vueMeta?.name ?? "reset-password-token-token",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47token_47_91token_93_46vueMeta?.path ?? "/reset-password/token/:token",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/reset-password/token/[token].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47token_47_91token_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47token_47_91token_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47reset_45password_47token_47_91token_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/reset-password/token/[token].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47rider_47_91slug_93_46vueMeta?.name ?? "rider-slug",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47rider_47_91slug_93_46vueMeta?.path ?? "/rider/:slug",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/rider/[slug].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47rider_47_91slug_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47rider_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47rider_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/rider/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47search_45results_47index_46vueMeta?.name ?? "search-results",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47search_45results_47index_46vueMeta?.path ?? "/search-results",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/search-results/index.vue",
    children: [],
    url: "/search-results",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47search_45results_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47search_45results_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47search_45results_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/search-results/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47shop_47index_46vueMeta?.name ?? "shop",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47shop_47index_46vueMeta?.path ?? "/shop",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/shop/index.vue",
    children: [],
    url: "/shop",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47shop_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47shop_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47shop_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47sire_45standings_47index_46vueMeta?.name ?? "sire-standings",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47sire_45standings_47index_46vueMeta?.path ?? "/sire-standings",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/sire-standings/index.vue",
    children: [],
    url: "/sire-standings",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47sire_45standings_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47sire_45standings_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47sire_45standings_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/sire-standings/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47site_45map_47index_46vueMeta?.name ?? "site-map",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47site_45map_47index_46vueMeta?.path ?? "/site-map",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/site-map/index.vue",
    children: [],
    url: "/site-map",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47site_45map_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47site_45map_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47site_45map_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/site-map/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47add_45stallion_45for_45sale_47index_46vueMeta?.name ?? "stallions-gallery-add-stallion-for-sale",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47add_45stallion_45for_45sale_47index_46vueMeta?.path ?? "/stallions-gallery/add-stallion-for-sale",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/stallions-gallery/add-stallion-for-sale/index.vue",
    children: [],
    url: "/stallions-gallery/add-stallion-for-sale",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47add_45stallion_45for_45sale_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47add_45stallion_45for_45sale_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47add_45stallion_45for_45sale_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/stallions-gallery/add-stallion-for-sale/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47index_46vueMeta?.name ?? "stallions-gallery",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47index_46vueMeta?.path ?? "/stallions-gallery",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/stallions-gallery/index.vue",
    children: [],
    url: "/stallions-gallery",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stallions_45gallery_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/stallions-gallery/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stl_45view_47_91slug_93_46vueMeta?.name ?? "stl-view-slug",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stl_45view_47_91slug_93_46vueMeta?.path ?? "/stl-view/:slug",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/stl-view/[slug].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stl_45view_47_91slug_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stl_45view_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47stl_45view_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/stl-view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47subscribe_47index_46vueMeta?.name ?? "subscribe",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47subscribe_47index_46vueMeta?.path ?? "/subscribe",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/subscribe/index.vue",
    children: [],
    url: "/subscribe",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47subscribe_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47subscribe_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47subscribe_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47terms_45conditions_47index_46vueMeta?.name ?? "terms-conditions",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47terms_45conditions_47index_46vueMeta?.path ?? "/terms-conditions",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/terms-conditions/index.vue",
    children: [],
    url: "/terms-conditions",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47terms_45conditions_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47terms_45conditions_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47terms_45conditions_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47account_47index_46vueMeta?.name ?? "users-account",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47account_47index_46vueMeta?.path ?? "/users/account",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/account/index.vue",
    children: [],
    url: "/users/account",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47account_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47account_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47account_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/account/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47address_47index_46vueMeta?.name ?? "users-address",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47address_47index_46vueMeta?.path ?? "/users/address",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/address/index.vue",
    children: [],
    url: "/users/address",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47address_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47address_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47address_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/address/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47digital_45photos_47index_46vueMeta?.name ?? "users-digital-photos",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47digital_45photos_47index_46vueMeta?.path ?? "/users/digital-photos",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/digital-photos/index.vue",
    children: [],
    url: "/users/digital-photos",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47digital_45photos_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47digital_45photos_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47digital_45photos_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/digital-photos/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47favorite_45photos_47index_46vueMeta?.name ?? "users-favorite-photos",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47favorite_45photos_47index_46vueMeta?.path ?? "/users/favorite-photos",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/favorite-photos/index.vue",
    children: [],
    url: "/users/favorite-photos",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47favorite_45photos_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47favorite_45photos_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47favorite_45photos_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/favorite-photos/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47index_46vueMeta?.name ?? "users-orders",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47index_46vueMeta?.path ?? "/users/orders",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/orders/index.vue",
    children: [],
    url: "/users/orders",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47view_47_91id_93_46vueMeta?.name ?? "users-orders-view-id",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47view_47_91id_93_46vueMeta?.path ?? "/users/orders/view/:id",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/orders/view/[id].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47view_47_91id_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47view_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47orders_47view_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/orders/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47subscriptions_47index_46vueMeta?.name ?? "users-subscriptions",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47subscriptions_47index_46vueMeta?.path ?? "/users/subscriptions",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/subscriptions/index.vue",
    children: [],
    url: "/users/subscriptions",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47subscriptions_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47subscriptions_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47users_47subscriptions_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/users/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47videos_47index_46vueMeta?.name ?? "videos",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47videos_47index_46vueMeta?.path ?? "/videos",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/videos/index.vue",
    children: [],
    url: "/videos",
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47videos_47index_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47videos_47index_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47videos_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/videos/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47view_47_91slug_93_46vueMeta?.name ?? "view-slug",
    path: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47view_47_91slug_93_46vueMeta?.path ?? "/view/:slug",
    file: "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/view/[slug].vue",
    children: [],
    meta: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47view_47_91slug_93_46vueMeta,
    alias: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47view_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47gitlab_45runner_47builds_47ntRqECycw_470_47nationalhorseman_47nationalhorseman_45laravel_458_47pages_47view_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/pages/view/[slug].vue").then(m => m.default || m)
  }
]