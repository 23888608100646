import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_OrkQhMqHci from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import plugins_date_picker_component_ts_yihJXvw6r3 from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/plugins/date-picker-component.ts";
import plugins_quilleditor_ts_cw4HdoCKHr from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/plugins/quilleditor.ts";
import plugins_range_slider_ts_ZVzDIi8nxi from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/plugins/range-slider.ts";
import plugins_swal_ts_QKlvgCdfgb from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/plugins/swal.ts";
import plugins_vee_validate_components_ts_b8xXLP1OQ6 from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/plugins/vee-validate-components.ts";
import plugins_vue_color_picker_component_ts_SWmltNOwLO from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/plugins/vue-color-picker-component.ts";
import plugins_vue_select_component_ts_e9AXqO83UF from "/home/gitlab-runner/builds/ntRqECycw/0/nationalhorseman/nationalhorseman-laravel-8/plugins/vue-select-component.ts";
export default [
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_OrkQhMqHci,
  plugins_date_picker_component_ts_yihJXvw6r3,
  plugins_quilleditor_ts_cw4HdoCKHr,
  plugins_range_slider_ts_ZVzDIi8nxi,
  plugins_swal_ts_QKlvgCdfgb,
  plugins_vee_validate_components_ts_b8xXLP1OQ6,
  plugins_vue_color_picker_component_ts_SWmltNOwLO,
  plugins_vue_select_component_ts_e9AXqO83UF
]