const NationalHorseman = {
    title: 'National Horseman',
    brother_title: 'National Horseman Arabian',
    brother_link: 'https://www.nationalhorsemanarabian.com/',
    privacy:`              National Horseman understands how important it is to keep your personal information confidential, especially online. Our commitment to protecting your privacy is outlined in the policies below. These privacy policies apply to the following National Horseman web site <a href="http://www.nationalhorseman.com/">http://www.nationalhorseman.com</a>.

                    <h2>Information Collected and How It&#39;s Used</h2>
                    <strong>Your IP Address -</strong> An IP (Internet Protocol) address is automatically assigned to your computer every time you establish a connection on the Internet. National Horseman uses your IP address to help diagnose problems with our server and to administer our web site. Your IP address is used to help identify you and to gather broad demographic information.<br />
                    <br />
                    <strong>Cookies</strong> - A cookie is used to store data on your computer&#39;s hard drive. We use cookies to store your e-mail ID or a unique identifier on your computer so that you don&#39;t have to re-enter it each time you visit National Horseman. We never use cookies to capture or contain personal information from your computer. The only personal information National Horseman has is what you give to us.<br />
                    <br />
                    <strong>Expunging Your Information From Our Records</strong> - To discontinue any e-mail solicitation please <a href="mailto: info@nationalhorseman.com ?subject=Website%20Contact%20-%20Privacy%20Policy">send us an e-mail</a>.&nbsp;&nbsp;Please note, however, that information is never completely removed from our history and disaster recovery archives.

                    <h2>Disclosure of Information to Third Parties</h2>
                    <strong>Personal Information</strong> - National Horseman defines personal information as your name, e-mail address, mailing address, and any other information that identifies you. National Horseman will not sell, trade, rent, or release your personal information to third parties without first asking your permission.<br />
                    <br />
                    <strong>Legal Issues</strong> - National Horseman discloses personal information when required by law or in the good-faith belief that such action is necessary for the following:

                    <ul>
                        <li>To conform to the edict of the law or comply with a legal process served on National Horseman or its web site.</li>
                        <li>To protect and defend the property rights of National Horseman, the National Horseman web site, or that of National Horseman guests.</li>
                        <li>Or to act under exigent circumstances to protect the personal safety of National Horseman guests, employees, or the general public.</li>
                    </ul>

                    <h2>Your Consent &amp; Contacting Us</h2>
                    Your Consent - By using the National Horseman web site, you consent to the collection, storage, and dissemination of personal data as described above. We will post any changes to our privacy policy on this page so you can readily review the most current policy. National Horseman reserves the right to e-mail you regarding changes to our privacy policy or any other matters National Horseman considers necessary for the administration of the site.<br />
                    <br />
                    <strong>How To Contact Us</strong> - If you have any questions regarding our privacy policy please contact <a href="mailto:info@nationalhorseman.com">info@nationalhorseman.com</a>
               `,
    site_map:`     <ul>
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <ul>
                            <li>
                                <a href="/subscribe">Subscribe</a>
                            </li>
                            <li>
                                <a href="/login">Login</a>
                            </li>
                            <li>
                                <a href="/contact">Contact Us</a>
                            </li>
                            <li>
                                    <li>Magazine</li>
                                    <li>
                                        <ul>
                                            <li>
                                                <a href="/subscribe">Subscribe</a>
                                            </li>

                                            <li>
                                                <a href="/advertise">Advertise</a>
                                            </li>

                                            <li>
                                                <a href="/issue">Digital Edition</a>
                                            </li>

                                            <li>
                                                <a href="/advertiser-gallery">Featured Advertisers</a>
                                            </li>
                                            <li>
                                                <a href="https://www.nationalhorsemanarabian.com/">Arabian</a>
                                            </li>
                                        </ul>
                                    </li>
                            </li>
                            <li>
                                <a href="/news">News</a>
                            </li>
                                <li>
                                    Results
                                </li>
                                <li>
                                    <ul>
                                        <li>
                                            <a href="/horse-shows">Horse Shows</a>
                                        </li>
                                        <li>
                                            <a href="/all-american-standings">All American Standings</a>
                                        </li>
                                        <li>
                                            <a href="/sire-standings">Sire Standings</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                <a href="/event">Calendar</a>
                            </li>

                            <li>
                                    <li><a href="/photo-gallery">Photos</a></li>
                            </li>
                            <li>
                                    <li><a href="/videos">Videos</a></li>
                            </li>
                            <li>
                                <li>Directory</li>
                                <li>
                                    <ul>
                                        <li>
                                            <a href="/directory-farms-stables"> Farms and Stables</a>
                                        </li>
                                        <li>
                                            <a href="/directory-products-services">Products and Services</a>
                                        </li>
                                    </ul>
                                </li>
                            </li>

                            <li>
                                <li>Horse Listings</li>
                                <li>
                                    <ul>
                                        <li>
                                            <a href="/directory-products-services">For Sale</a>
                                        </li>
                                        <li>
                                            <a href="/stallions-gallery">Stallions</a>
                                        </li>
                                    </ul>
                                </li>
                            </li>

                            <li>
                                <li><a href="/shop">Shop</a></li>
                            </li>
                            <li>

                                <li><a href="/employment">Careers</a></li>

                            </li>
                            <li>

                                    <li><a>About</a></li>
                                    <li>
                                        <ul>
                                            <li><a href="/our-team">Our Team</a></li>
                                            <li><a href="/our-history">Our History</a></li>
                                            <li><a href="/contact">Contact Us</a></li>
                                        </ul>
                                    </li>

                            </li>

                            <li>
                                <a href="/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/terms-conditions">Terms and conditions</a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <ul>
                            <li><a href="/advertiser-gallery">Advertisers</a></li>
                        </ul>
                    </li>

                </ul>`,
    logo_big:'/logo_a.svg',
    logo_small:'/logo_e.svg',
    logo_white:'/logo_c_white.svg',
    menu_title:'Arabian',
    facebook:'https://www.facebook.com/nationalhorseman',
    instagram:'https://www.instagram.com/national_horseman/',
    youtube:'https://www.youtube.com/channel/UC-VVCl9tMMbHFn73_Y010Gg',
    twitter:'https://twitter.com/tnh1865',
    gtag_src: null,
    gtag_script: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','//www.google-analytics.com/analytics.js','ga');ga('create', 'UA-47419421-1', 'auto');ga('create', 'UA-61839060-1', 'auto', 'secondTracker');ga('secondTracker.send', 'pageview');ga('send', 'pageview');`,
}

const NationalHorsemanArabian = {
    title: 'National Horseman Arabian',
    brother_title: 'National Horseman',
    brother_link: 'https://www.nationalhorseman.com/',
    privacy:`           National Horseman Arabian understands how important it is to keep your personal information confidential, especially online. Our commitment to protecting your privacy is outlined in the policies below. These privacy policies apply to the following National Horseman Arabian web site <a href="http://www.nationalhorsemanarabian.com/">http://www.nationalhorsemanarabian.com</a>.

                    <h2>Information Collected and How It&#39;s Used</h2>
                    <strong>Your IP Address -</strong> An IP (Internet Protocol) address is automatically assigned to your computer every time you establish a connection on the Internet. National Horseman Arabian uses your IP address to help diagnose problems with our server and to administer our web site. Your IP address is used to help identify you and to gather broad demographic information.<br />
                    <br />
                    <strong>Cookies</strong> - A cookie is used to store data on your computer&#39;s hard drive. We use cookies to store your e-mail ID or a unique identifier on your computer so that you don&#39;t have to re-enter it each time you visit National Horseman Arabian. We never use cookies to capture or contain personal information from your computer. The only personal information National Horseman Arabian has is what you give to us.<br />
                    <br />
                    <strong>Expunging Your Information From Our Records</strong> - To discontinue any e-mail solicitation please <a href="mailto: info@nationalhorseman.com ?subject=Website%20Contact%20-%20Privacy%20Policy">send us an e-mail</a>.&nbsp;&nbsp;Please note, however, that information is never completely removed from our history and disaster recovery archives.

                    <h2>Disclosure of Information to Third Parties</h2>
                    <strong>Personal Information</strong> - National Horseman Arabian defines personal information as your name, e-mail address, mailing address, and any other information that identifies you. National Horseman Arabian will not sell, trade, rent, or release your personal information to third parties without first asking your permission.<br />
                    <br />
                    <strong>Legal Issues</strong> - National Horseman Arabian discloses personal information when required by law or in the good-faith belief that such action is necessary for the following:

                    <ul>
                        <li>To conform to the edict of the law or comply with a legal process served on National Horseman Arabian or its web site.</li>
                        <li>To protect and defend the property rights of National Horseman Arabian, the National Horseman Arabian web site, or that of National Horseman Arabian guests.</li>
                        <li>Or to act under exigent circumstances to protect the personal safety of National Horseman Arabian guests, employees, or the general public.</li>
                    </ul>

                    <h2>Your Consent &amp; Contacting Us</h2>
                    Your Consent - By using the National Horseman Arabian web site, you consent to the collection, storage, and dissemination of personal data as described above. We will post any changes to our privacy policy on this page so you can readily review the most current policy. National Horseman Arabian reserves the right to e-mail you regarding changes to our privacy policy or any other matters National Horseman Arabian considers necessary for the administration of the site.<br />
                    <br />
                    <strong>How To Contact Us</strong> - If you have any questions regarding our privacy policy please contact <a href="mailto:info@nationalhorseman.com">info@nationalhorseman.com</a>
               `,
    site_map:`     <ul>
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <ul>
                            <li>
                                <a href="/subscribe">Subscribe</a>
                            </li>
                            <li>
                                <a href="/login">Login</a>
                            </li>
                            <li>
                                <a href="/contact">Contact Us</a>
                            </li>
                            <li>
                                    <li>Magazine</li>
                                    <li>
                                        <ul>
                                            <li>
                                                <a href="/subscribe">Subscribe</a>
                                            </li>

                                            <li>
                                                <a href="/advertise">Advertise</a>
                                            </li>

                                            <li>
                                                <a href="/issue">Digital Edition</a>
                                            </li>

                                            <li>
                                                <a href="/advertiser-gallery">Featured Advertisers</a>
                                            </li>
                                            <li>
                                                <a href="https://www.nationalhorseman.com/">Saddlebred/Morgan</a>
                                            </li>
                                        </ul>
                                    </li>
                            </li>
                            <li>
                                <a href="/news">News</a>
                            </li>
                            <li>
                                <a href="/event">Calendar</a>
                            </li>

                            <li>
                                    <li><a href="/photo-gallery">Photos</a></li>
                            </li>
                            <li>
                                    <li><a href="/videos">Videos</a></li>
                            </li>
                            <li>
                                <li>Directory</li>
                                <li>
                                    <ul>
                                        <li>
                                            <a href="/directory-farms-stables"> Farms and Stables</a>
                                        </li>
                                        <li>
                                            <a href="/directory-products-services">Products and Services</a>
                                        </li>
                                    </ul>
                                </li>
                            </li>

                            <li>
                                <li>Horse Listings</li>
                                <li>
                                    <ul>
                                        <li>
                                            <a href="/directory-products-services">For Sale</a>
                                        </li>
                                        <li>
                                            <a href="/stallions-gallery">Stallions</a>
                                        </li>
                                    </ul>
                                </li>
                            </li>

                            <li>
                                <li><a href="/shop">Shop</a></li>
                            </li>
                            <li>

                                <li><a href="/employment">Careers</a></li>

                            </li>
                            <li>

                                    <li><a>About</a></li>
                                    <li>
                                        <ul>
                                            <li><a href="/our-team">Our Team</a></li>
                                            <li><a href="/our-history">Our History</a></li>
                                            <li><a href="/contact">Contact Us</a></li>
                                        </ul>
                                    </li>

                            </li>

                            <li>
                                <a href="/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/terms-conditions">Terms and conditions</a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <ul>
                            <li><a href="/advertiser-gallery">Advertisers</a></li>
                        </ul>
                    </li>

                </ul>`,
    logo_big:'/logo_b.svg',
    logo_small:'/logo_c.svg',
    logo_white:'/logo_e_white.svg',
    menu_title:'Saddlebred/Morgan',
    facebook:'https://www.facebook.com/NationalHorsemanArabian',
    instagram:'https://www.instagram.com/national_horseman_arabian/',
    youtube:'https://www.youtube.com/channel/UC-VVCl9tMMbHFn73_Y010Gg',
    twitter:'https://twitter.com/NH_Arabian',
    gtag_src: 'https://www.googletagmanager.com/gtag/js?id=G-QRT13BDWDL',
    gtag_script: `  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-QRT13BDWDL');`,
}

export const getDomainOptions = () => {
    const domain = import.meta.env.VITE_APP_TITLE;

    if (domain.includes('Arabian')) {
        return NationalHorsemanArabian;
    } else {
        return NationalHorseman;
    }
}
