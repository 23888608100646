<template>
  <div class="h-full">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script setup>
import {useSiteURL, useSiteTitle, useSiteWeb, useSiteApp} from "./composables/state";
import {http} from "~/utils/http";
import {useHead, useRuntimeConfig} from "nuxt/app";
import {computed, ref} from "vue";
import {useDomainStore} from "./stores/main";

const config = useRuntimeConfig();
const siteURL = useSiteURL();
const siteTitle = useSiteTitle();
const siteWeb = useSiteWeb();
const siteApp= useSiteApp();
siteURL.value = config.s3URL;
siteTitle.value = config.title;
siteWeb.value = config.siteWEB;
siteApp.value = config.siteURL;
const domainStore = useDomainStore();
const gtag_src = computed(() => domainStore.gtag_src);
const gtag_script = computed(() => domainStore.gtag_script);

const idToken = useCookie("idToken");

http.defaults.headers.common.Authorization = "Bearer "+idToken.value;

const scripts = ref([]);
if(gtag_src.value){
  scripts.value.push({src:gtag_src.value, async: true})
}
if(gtag_script.value){
  scripts.value.push({type:'text/javascript',children:gtag_script.value})
}


useHead({
  link: [
    { rel: 'preconnect', href: siteURL.value },
    { rel: 'preconnect', href: config.siteURL },
  ],
  script:  computed(() => scripts.value),
})

</script>
