export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Since its beginnings in 1865, National Horseman has a long history of setting the standard for the Saddlebred industry and the show horse world."},{"name":"keywords","content":"National Horseman Arabian"},{"property":"fb:app_id","content":"939401369470552"},{"name":"google-site-verification","content":"_cGq_OnlesC8JVb4K5wucMnc2ERgAuoBqdoExbKkEwo"},{"name":"apple-itunes-app","content":"app-id=1124379862"},{"name":"theme-color","content":"#ffffff"},{"property":"og:title","content":"National Horseman Arabian"},{"property":"og:type","content":"article"},{"property":"og:description","content":"Since its beginnings in 1865, National Horseman has a long history of setting the standard for the Saddlebred industry and the show horse world."},{"property":"og:url","content":"https://nationalhorsemanarabian.com"},{"property":"og:image","content":"https://nationalhorsemanarabian.com/facebook/nha-icon.jpg"}],"link":[{"rel":"apple-touch-icon","href":"/favicon/arabian/apple-touch-icon.png","sizes":"180x180"},{"rel":"icon","href":"/favicon/arabian/favicon-32x32.png","sizes":"32x32"},{"rel":"icon","href":"/favicon/arabian/favicon-16x16.png","sizes":"16x16"},{"rel":"manifest","href":"/favicon/arabian/manifest.json"},{"rel":"mask-icon","href":"/favicon/safari-pinned-tab.svg","color":"#5bbad5"},{"rel":"shortcut icon","href":"/favicon/arabian/favicon.ico"},{"rel":"stylesheet","href":"//cdn-images.mailchimp.com/embedcode/classic-061523.css","type":"text/css"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"},"title":"National Horseman Arabian"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"