import http from "axios";
import { useMainStore } from '~/stores/main'
import {navigateTo} from "nuxt/app";

const baseUrl = import.meta.env.VITE_SITE_URL;
http.defaults.timeout = 0;
http.defaults.headers.common.Authorization = null;
http.defaults.headers.post["Content-Type"] = "application/json";
http.defaults.baseURL = baseUrl;
http.defaults.withCredentials = true;

http.interceptors.response.use((response) => {
    return response
}, function(error) {
    if(error.response?.status === 403){
        const mainStore = useMainStore();
        mainStore.setOverlay(false);
        mainStore.isAuthorization(false);
    }

    if(error.response?.status === 401){
        if(window.location.pathname.includes('/admin') && !window.location.pathname.includes('/admin/login')){
            navigateTo('/admin/login');
        }
    }
    return Promise.reject(error)
})

export { http };
