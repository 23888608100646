import { joinURL } from 'ufo'
// import {} from '~image'
// @ts-ignore
export function getImage(src, { modifiers, baseURL } = {}, { options, nuxtContext, $img }) {
    const { width, height, format, fit, ...providerModifiers } = modifiers
    // @ts-ignore
    const operations = []
    // process modifiers
    // @ts-ignore
    const operationsString = operations.join(',')
    return {
        url: joinURL(baseURL, operationsString, src)
    }
}